<template>
    <div class="flex flex-col justify-between h-full">
        <div class="flex flex-grow min-h-0">
            <div class="flex-shrink-0 w-1/2 border-r overflow-y-auto text-xs">
                <SpaceItem
                    ref="space"
                    :space="rootSpace"
                    :selected-space="selectedSpace"
                    :force-expanded="true"
                    @select="s => (selectedSpace = s)"
                />
            </div>
            <div
                v-if="selectedSpace && selectedSpace.parent_space_id"
                class="flex flex-col flex-shrink-0 w-1/2 pl-8 overflow-y-auto"
            >
                <SpaceForm
                    ref="form"
                    :key="selectedSpace.space_id"
                    :property-id="propertyId"
                    :space="selectedSpace"
                />
            </div>
        </div>
        <div
            v-if="selectedSpace"
            class="flex pt-4"
        >
            <button
                type="button"
                class="btn-primary btn-transparent mr-4"
                @click="deselectSpace"
            >
                cancel
            </button>
            <button
                v-if="selectedSpace.space_id"
                type="button"
                class="btn-primary"
                @click="createChildSpace"
            >
                add child
            </button>
            <div class="flex-grow" />
            <button
                v-if="selectedSpace.parent_space_id"
                type="button"
                class="btn-primary btn-solid"
                @click="saveSpace"
            >
                save
            </button>
        </div>
        <Loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from '@/mixins/NotifyMixin';
import LocationsDataMixin from '@/views/locations/LocationsDataMixin';
import Loader from '@/components/ui/Loader';
import SpaceItem from '@/components/locations/SpaceItem';
import SpaceForm from '@/components/locations/SpaceForm';

const ROOT_ID = '00000000-0000-0000-0000-000000000000';

export default {
    components: { SpaceForm, SpaceItem, Loader },

    mixins: [ModalNavigation, NotifyMixin, LocationsDataMixin],

    data() {
        return {
            spaces: {
                [ROOT_ID]: {},
            },

            selectedSpace: null,
            loading: false,
        };
    },

    computed: {
        propertyId() {
            return this.$route.params.propertyId;
        },

        rootSpace() {
            return this.spaces[ROOT_ID];
        },
    },

    watch: {
        initialized(value) {
            if (value) {
                this.loadSpaces();
            }
        },

        selectedSpace(_, oldSpace) {
            if (!oldSpace || oldSpace.space_id) {
                return;
            }

            const { children } = this.spaces[oldSpace.parent_space_id];

            if (!children) {
                return;
            }

            const index = children.findIndex(space => !space.space_id);

            if (index < 0) {
                return;
            }

            children.splice(index, 1);
        },
    },

    mounted() {
        if (this.initialized) {
            this.loadSpaces();
        }
    },

    methods: {
        async loadSpaces() {
            this.loading = true;

            const property = this.realProperties[this.propertyId];

            try {
                const spaces = {
                    [ROOT_ID]: {
                        space_id: ROOT_ID,
                        space_name: property.property_name,
                        space_type: property.property_type,
                        children: [],
                    },
                };

                const allSpaces = await this.$locationsDataProvider.getList('spaces', { propertyId: this.propertyId });
                allSpaces.forEach(space => {
                    const parentId = space.parent_space_id ?? ROOT_ID;

                    if (!spaces[parentId]) {
                        spaces[parentId] = {
                            space_id: parentId,
                            children: [],
                        };
                    }

                    spaces[parentId].children.push(space);

                    if (spaces[space.space_id]) {
                        Object.assign(spaces[space.space_id], space);
                    } else {
                        space.children = [];
                        spaces[space.space_id] = space;
                    }
                });

                this.spaces = spaces;

                if (this.selectedSpace) {
                    this.selectedSpace = this.spaces[this.selectedSpace.space_id];
                }
            } catch (e) {
                this.notifyError(e.message);
            } finally {
                this.loading = false;
            }
        },

        deselectSpace() {
            this.selectedSpace = null;
        },

        createChildSpace() {
            const space = {
                parent_space_id: this.selectedSpace.space_id,
                parent_space_type: this.selectedSpace.space_type,
                children: [],
            };

            this.selectedSpace.children.push(space);
            this.selectedSpace = space;

            this.$nextTick(() => {
                this.$refs.space.expandByPath(this.getSelectionPath());
            });
        },

        saveSpace() {
            this.$refs.form.submit(this.loadSpaces);
        },

        getSelectionPath() {
            const path = [];
            let space = this.selectedSpace;
            while (space && space.space_id !== ROOT_ID) {
                path.unshift(space.space_id);
                space = this.spaces[space.parent_space_id];
            }
            return path;
        },
    },
};
</script>
