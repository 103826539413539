<template>
    <vuetable
        row-class="table-row"
        :api-mode="false"
        :fields="fields"
        :data="items"
        :css="css.table"
        :track-by="trackBy"
        @vuetable:row-clicked="handleRowClicked"
    />
</template>

<script>
import Vuetable from 'vue3-vuetable';

const css = {
    table: {
        tableClass: 'table-auto w-full',
        tableBodyClass: '',
        tableHeaderClass: 'px-4 py-2',
        tableWrapper: '',
        loadingClass: 'loading',
        ascendingIcon: 'blue chevron up icon',
        descendingIcon: 'blue chevron down icon',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'grey sort icon',
        handleIcon: 'grey sidebar icon',
    },
};

export default {
    components: { Vuetable },

    props: {
        css: {
            type: Object,
            default() {
                return css;
            },
        },

        fields: {
            type: Array,
            required: true,
        },

        items: {
            type: Array,
            required: true,
        },

        trackBy: {
            type: String,
            default: 'id',
        },
    },

    emits: ['row-clicked'],

    methods: {
        handleRowClicked({ data }) {
            this.$emit('row-clicked', data);
        },
    },
};
</script>
