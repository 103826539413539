<template>
    <FinalForm
        :initial-values="space"
        :submit="handleSubmit"
    >
        <template #default="props">
            <form
                ref="form"
                @submit="props.handleSubmit"
            >
                <div class="form-row">
                    <TextField
                        name="space_name"
                        label="name*"
                        :validate="required"
                    />
                </div>
                <div class="form-row">
                    <TextField
                        name="space_description"
                        label="description*"
                        :validate="required"
                    />
                </div>
                <div class="form-row">
                    <SelectInput
                        name="space_type"
                        label="type*"
                        :options="typeOptions"
                        open-direction="bottom"
                        :validate="required"
                    />
                </div>
                <div class="form-row">
                    <TextField
                        name="space_sq_ft"
                        label="square, ft"
                        :validate="number"
                    />
                </div>
            </form>
        </template>
    </FinalForm>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import TextField from '@/components/form/TextField';
import SelectInput from '@/components/form/SelectInput';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import LocationsDataMixin from '@/views/locations/LocationsDataMixin';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    components: { SelectInput, TextField, FinalForm },

    mixins: [LocationsDataMixin, ValidatorMixin, NotifyMixin],

    props: {
        propertyId: {
            type: [Number, String],
            required: true,
        },

        space: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            callback: null,
        };
    },

    computed: {
        typeOptions() {
            return Object.values(this.spaceTypes).map(type => ({
                key: type.space_type_name,
                value: type.space_type_name,
            }));
        },
    },

    methods: {
        submit(callback) {
            this.callback = callback;
            this.$refs.form.requestSubmit();
        },

        async handleSubmit(values) {
            const data = { ...values, children: null };

            if (data.parent_space_id === '00000000-0000-0000-0000-000000000000') {
                data.parent_space_type = null;
            }

            try {
                if (data.space_id) {
                    await this.$locationsDataProvider.update('spaces', { propertyId: this.propertyId, data });
                } else {
                    await this.$locationsDataProvider.create('spaces', { propertyId: this.propertyId, data });
                }

                if (this.callback) {
                    this.callback();
                }
            } catch (e) {
                this.notifyError(e.message);
            }
        },
    },
};
</script>
