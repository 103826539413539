<template>
    <div>
        <div v-if="data">
            <FinalForm
                :initial-values="data"
                :submit="handleSubmit"
            >
                <template #default="props">
                    <form @submit="props.handleSubmit">
                        <div class="form-row">
                            <TextField
                                v-if="id"
                                name="space_type_id"
                                label="id"
                                :edit-mode="false"
                            />
                        </div>
                        <div class="form-row">
                            <TextField
                                name="space_type_name"
                                label="name*"
                                :validate="required"
                            />
                        </div>
                        <ModalFooter
                            :footer="{ primaryButton: 'save', tertiaryButton: 'cancel' }"
                            :primary="() => {}"
                            :tertiary="handleCancel"
                            :disable-primary="props.invalid"
                        />
                    </form>
                </template>
            </FinalForm>
        </div>

        <Loader
            :loading="loading"
            :backdrop="true"
        />
    </div>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import ModalNavigation from '@/mixins/ModalNavigation';
import Loader from '@/components/ui/Loader';
import ModalFooter from '@/components/ui/modals/ModalFooter';
import TextField from '@/components/form/TextField';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import LocationsDataMixin from '../LocationsDataMixin';

export default {
    components: { Loader, FinalForm, TextField, ModalFooter },

    mixins: [ModalNavigation, LocationsDataMixin, ValidatorMixin],

    data() {
        return {
            saving: false,
        };
    },

    computed: {
        id() {
            return this.$route.params.id;
        },

        loading() {
            return !this.initialized || this.saving;
        },

        data() {
            return this.id ? this.spaceTypes[this.id] : {};
        },
    },

    methods: {
        handleSubmit(values) {
            if (this.loading) {
                return;
            }

            this.saving = true;

            (this.id ? this.updateEntity(values) : this.createEntity(values))
                .then(() => {
                    this.loadLocationsData();
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        handleCancel(e) {
            e.preventDefault();
            this.close();
        },

        createEntity(values) {
            return this.$locationsDataProvider.create('spaceTypes', { data: values }).then(() => {
                this.notifySuccess('Space type created');
                this.close();
            });
        },

        updateEntity(values) {
            return this.$locationsDataProvider.update('spaceTypes', { data: values }).then(() => {
                this.notifySuccess('Space type updated');
            });
        },
    },
};
</script>
