import { createNamespacedHelpers } from 'vuex';
import NotifyMixin from '@/mixins/NotifyMixin';

const { mapActions, mapGetters } = createNamespacedHelpers('locations');

export default {
    mixins: [NotifyMixin],

    computed: {
        ...mapGetters(['initialized', 'spaceTypes', 'realProperties']),
    },

    methods: {
        ...mapActions(['setInitialized', 'setSpaceTypes', 'setRealProperties']),

        loadLocationsData() {
            Promise.all([
                this.$locationsDataProvider.getList('spaceTypes').then(this.setSpaceTypes),
                this.$locationsDataProvider.getList('properties').then(this.setRealProperties),
            ])
                .then(() => this.setInitialized(true))
                .catch(e => this.notifyError(e.message));
        },
    },

    mounted() {
        if (!this.initialized) {
            this.loadLocationsData();
        }
    },
};
