<template>
    <div>
        <List
            :fields="fields"
            :items="items"
            track-by="property_id"
            @row-clicked="handleRowClicked"
        />
    </div>
</template>

<script>
import List from '@/components/locations/List';
import ModalNavigation from '@/mixins/ModalNavigation';
import LocationsDataMixin from '../LocationsDataMixin';

const fields = [
    {
        name: 'property_id',
        title: 'id',
    },
    {
        name: 'property_name',
        title: 'name',
    },
    {
        name: 'property_type',
        title: 'type',
    },
];

export default {
    components: { List },

    mixins: [ModalNavigation, LocationsDataMixin],

    props: {
        fields: {
            type: Array,
            default() {
                return fields;
            },
        },
    },

    computed: {
        items() {
            return Object.values(this.realProperties);
        },
    },

    methods: {
        handleRowClicked(item) {
            this.$router.push({
                name: 'locations.properties.details',
                params: {
                    propertyId: item.property_id,
                },
            });
        },
    },
};
</script>
