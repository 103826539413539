<template>
    <div>
        <div
            ref="item"
            class="flex h-6 items-center text-xs"
        >
            <div v-if="!forceExpanded">
                <Icon
                    v-if="space.children.length"
                    :name="expanded ? 'minusSquare' : 'plusSquare'"
                    class="w-3 h-3 mr-1 cursor-pointer"
                    @click="expanded = !expanded"
                />
                <div
                    v-else
                    class="w-4 flex-shrink-0"
                />
            </div>
            <div
                class="flex flex-grow h-6 items-center whitespace-no-wrap cursor-pointer"
                :class="{ 'bg-blue-300': isSelected, 'hover:bg-blue-100': !isSelected }"
                @click="$emit('select', space)"
            >
                <span class="font-bold mr-2">{{ space.space_name }}</span>
                <span>{{ space.space_type }}</span>
            </div>
        </div>
        <div
            v-if="expanded || forceExpanded"
            class="pl-4"
        >
            <SpaceItem
                v-for="child in space.children"
                ref="children"
                :key="child.space_id"
                :space="child"
                :selected-space="selectedSpace"
                @select="s => $emit('select', s)"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'SpaceItem',

    components: { Icon },

    props: {
        space: {
            type: Object,
            required: true,
        },

        selectedSpace: {
            type: Object,
            required: false,
            default: null,
        },

        forceExpanded: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['select'],

    data() {
        return {
            expanded: false,
        };
    },

    computed: {
        isSelected() {
            return this.space === this.selectedSpace;
        },
    },

    beforeUnmount() {
        if (this.isSelected) {
            this.$emit('select', null);
        }
    },

    methods: {
        expandByPath([head, ...tail]) {
            this.expanded = true;

            if (this.isSelected) {
                this.$refs.item.scrollIntoView(false);
            }

            const index = this.space.children.findIndex(child => child.space_id === head);

            if (index < 0) {
                return;
            }

            this.$nextTick(() => {
                this.$refs.children[index].expandByPath(tail);
            });
        },
    },
};
</script>
